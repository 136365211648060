/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&display=swap');

// Custom colors for Scan It Forward statuses (also in variables.scss)

.ion-color-prep {
	--ion-color-base: var(--ion-color-prep);
	--ion-color-base-rgb: var(--ion-color-prep-rgb);
	--ion-color-contrast: var(--ion-color-prep-contrast);
	--ion-color-contrast-rgb: var(--ion-color-prep-contrast-rgb);
	--ion-color-shade: var(--ion-color-prep-shade);
	--ion-color-tint: var(--ion-color-prep-tint);
}

.ion-color-scan {
	--ion-color-base: var(--ion-color-scan);
	--ion-color-base-rgb: var(--ion-color-scan-rgb);
	--ion-color-contrast: var(--ion-color-scan-contrast);
	--ion-color-contrast-rgb: var(--ion-color-scan-contrast-rgb);
	--ion-color-shade: var(--ion-color-scan-shade);
	--ion-color-tint: var(--ion-color-scan-tint);
}

.ion-color-qc {
	--ion-color-base: var(--ion-color-qc);
	--ion-color-base-rgb: var(--ion-color-qc-rgb);
	--ion-color-contrast: var(--ion-color-qc-contrast);
	--ion-color-contrast-rgb: var(--ion-color-qc-contrast-rgb);
	--ion-color-shade: var(--ion-color-qc-shade);
	--ion-color-tint: var(--ion-color-qc-tint);
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none; 
    margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield;
}